<template>
    <div class="seat-map-editor" :style="seatMapStyles" >
        <div class="map-canvas">
            <canvas ref="canvas" :width="seatMapStyles.width" :height="seatMapStyles.height"  @mousedown="handleCanvasMouseDown" @mousemove="handleCanvasMouseMove" @mouseup="handleCanvasMouseUp" @mouseleave="handleCanvasMouseLeave"></canvas>
            <div v-if="tooltipText" :style="tooltipStyle" class="tooltip">
                {{ tooltipText }}
            </div>
        </div>
    </div>
</template>

<script>
// import MapSection from './MapSection.vue';
export default {
    // components: { MapSection },
    props: {
        renderCanvas: { type: Boolean, default: false },
        renderSection: { type: Boolean, default: false },
        seatMapStyles: { type: Object, function() { return {} } },
        mapData: { type: Object, default: function () { return {} } },
        mapSections: { type: Array, default: () => [] },
        scale: { type: Number, default: 1},
    },
    data() {
        return {
            canvas: null,
            ctx: null,
            selectedSectionIndex: -1,
            selectedSection: null,
            isDragging: false,
            dragOffsetX: 0,
            dragOffsetY: 0,
            isSelected: false,
            tooltipText: '',
            tooltipStyle: {
                display: 'none',
                position: 'absolute',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                color: '#fff',
                padding: '8px',
                borderRadius: '4px',
            },
        };
    },
    watch: {
        renderCanvas: {
            handler(val) {
                if (val) {
                    console.log(val);
                    this.setupCanvas();
                }
            },
        },
        renderSection: {
            handler(val) {
                if (val) {
                    //  this.drawSections(this.ctx);
                    this.setupCanvas(this.ctx);
                }
            }
        },
    },
    mounted() {
        this.setupCanvas();
    },
    methods: {
        async setupCanvas() {
            this.canvas = this.$refs.canvas;
            this.ctx = this.canvas.getContext('2d');
            // Clear canvas
            this.ctx.clearRect(0, 0, this.mapData.plan.map_width, this.mapData.plan.map_height);
            await this.drawMap();
            this.drawSections(this.ctx);
        },
        /** Map Draw function */
        
        // drawMap() {
        //     console.log(this.mapData);
        //     // Clear canvas
        //     this.ctx.clearRect(0, 0, this.mapData.plan.map_width, this.mapData.plan.map_height);
        //     // Draw the grid
        //     for (let x = 0; x < this.mapData.plan.map_width; x += this.mapData.plan.map_grid_wh) {
        //         this.drawMapVerticalLine(this.ctx, x);
        //     }
        //     for (let y = 0; y < this.mapData.plan.map_height; y += this.mapData.plan.map_grid_wh) {
        //         this.drawMapHorizontalLine(this.ctx, y);
        //     }
        // },
        async drawMap() {
            return new Promise((resolve) => {
                 for (let x = 0; x < this.mapData.plan.map_width; x += this.mapData.plan.map_grid_wh) {
                    this.drawMapVerticalLine(this.ctx, x);
                }
                for (let y = 0; y < this.mapData.plan.map_height; y += this.mapData.plan.map_grid_wh) {
                    this.drawMapHorizontalLine(this.ctx, y);
                }
                resolve();

                /*
                this.ctx.clearRect(0, 0, this.mapData.plan.map_width, this.mapData.plan.map_height);
                if (this.mapData.plan.map_image) {
                    const backgroundImage = new Image();
                    backgroundImage.src = this.s3BucketURL + this.mapData.plan.map_image;//require('@/assets/images/mapbg1.jpg');  // Replace with your image filename and path
                    backgroundImage.addEventListener('load', () => {
                    if (this.ctx) {
                        this.ctx.drawImage(backgroundImage, 0, 0, this.mapData.plan.map_width, this.mapData.plan.map_height);
                        resolve(); // Resolve the promise when the image is drawn
                    } else {
                        console.error('Canvas context is not valid.');
                        resolve(); // Resolve the promise even if there's an error
                    }
                    });
                } else {
                    // Draw the grid
                    for (let x = 0; x < this.mapData.plan.map_width; x += this.mapData.plan.map_grid_wh) {
                        this.drawMapVerticalLine(this.ctx, x);
                    }
                    for (let y = 0; y < this.mapData.plan.map_height; y += this.mapData.plan.map_grid_wh) {
                        this.drawMapHorizontalLine(this.ctx, y);
                    }
                    resolve();
                }*/
               
            });
        },
        drawMapHorizontalLine(ctx, y) {
            ctx.beginPath();
            ctx.moveTo(0, y);
            ctx.lineTo(this.mapData.plan.map_width, y);
            ctx.strokeStyle =  this.mapData.plan.map_width;
            ctx.stroke();
        },
        drawMapVerticalLine(ctx, x) {
            ctx.beginPath();
            ctx.moveTo(x, 0);
            ctx.lineTo(x, this.mapData.plan.map_height);
            ctx.strokeStyle = this.mapData.plan.map_grid_line_color;
            ctx.stroke();
        },
        /** Event Handling */
        handleCanvasMouseDown(event) {
            // console.log("handleCanvasMouseDown");
            const canvas = this.canvas;
            const canvasRect = canvas.getBoundingClientRect();
            const mouseX = event.clientX - canvasRect.left;
            const mouseY = event.clientY - canvasRect.top;
            // let grid = this.mapData.plan.map_seat_wh;
            let grid = this.mapData.plan.map_grid_wh;
            this.selectedSection = this.mapSections.find(section => {
                let sectionWidth = section.section_seats * grid;
                let sectionHeight = section.section_rows * grid;
                return mouseX >= section.x && mouseX <= section.x + sectionWidth && mouseY >= section.y && mouseY <= section.y + sectionHeight;
            });
            if (this.selectedSection) {
                this.isDragging = true;
                this.dragOffsetX = mouseX - this.selectedSection.x;
                this.dragOffsetY = mouseY - this.selectedSection.y;
                 // Add a mousemove event listener to track mouse movement
                canvas.addEventListener('mousemove', this.handleCanvasMouseMove);
            }
        },
        handleCanvasMouseUp(event) {
            // console.log("handleCanvasMouseUp");
            if (this.isDragging && this.selectedSection) {
                const canvas = this.canvas;
                const canvasRect = canvas.getBoundingClientRect();
                const mouseX = event.clientX - canvasRect.left;
                const mouseY = event.clientY - canvasRect.top;

                this.selectedSection.x = (mouseX - this.dragOffsetX) < 0? 0: (mouseX - this.dragOffsetX);
                this.selectedSection.y = (mouseY - this.dragOffsetY) < 0? 0: (mouseY - this.dragOffsetY);

                this.setupCanvas(); // Redraw canvas
            }
            // Remove the mousemove event listener when the mouse button is released
            this.canvas.removeEventListener('mousemove', this.handleCanvasMouseMove);
            this.isDragging = false;
        },
        handleCanvasMouseMove(event) {
            console.log("handleCanvasMouseMove");
            // const canvas = this.canvas;
            // const rect = canvas.getBoundingClientRect();
            // const mouseX = event.clientX - rect.left;
            // const mouseY = event.clientY - rect.top;

            // this.tooltipText = '';
            // let grid = this.mapData.plan.map_grid_wh;

            // this.mapSections.forEach(section => {
            //     if (
            //         mouseX >= section.x &&
            //         mouseX <= section.x + (section.section_seats * grid) &&
            //         mouseY >= section.y &&
            //         mouseY <= section.y + (section.section_rows * grid)
            //     ) {
            //         // console.log("yessss enter mouse");
            //         this.tooltipText = section.section_name;
            //         this.tooltipStyle.display = 'block';
            //         this.tooltipStyle.left = mouseX + 'px';
            //         this.tooltipStyle.top = mouseY + 10 + 'px'; // Position below the cursor
            //     }
            // });
            if (this.isDragging && this.selectedSection) {
                console.log("draging");
                const canvas = this.canvas;
                const canvasRect = canvas.getBoundingClientRect();
                const mouseX = event.clientX - canvasRect.left;
                const mouseY = event.clientY - canvasRect.top;
                // let grid = this.mapData.plan.map_grid_wh;
                
                // Update the position of the selected section based on mouse movement
                this.selectedSection.x = mouseX - this.dragOffsetX;
                this.selectedSection.y = mouseY - this.dragOffsetY;
                this.setupCanvas();
                // You may also need to redraw or update your canvas here
                // This depends on your specific implementation
            }
        },
        handleCanvasMouseLeave() {
            this.tooltipText = '';
            this.tooltipStyle.display = 'none';
        },
        /** Draw functions */
        drawSections(ctx) {
            this.mapSections.forEach(section => {
                ctx.fillStyle = section.section_bg_color;
                let height = section.section_rows * this.mapData.plan.map_grid_wh;
                let width = section.section_seats * this.mapData.plan.map_grid_wh;

                // Draw border rectangle for the section
                ctx.strokeStyle = '#4FAEAF'; // Border color
                // ctx.lineWidth = 2; // Border width
               
                ctx.lineJoin = "round";
                ctx.lineWidth = "2";
                
                if (section.section_type == "Assigned Seating") {
                    ctx.strokeRect(section.x, section.y, width, height);
                    // Draw inner lines within each section
                    ctx.fillRect(section.x, section.y, width, height);
                    // this.drawSectionLines(ctx, section);
                    // this.drawSeatNumbering(ctx, section);
                    this.drawStage(ctx,section)
                } else if (section.section_type == 'Stage') {
                    ctx.fillRect(section.x, section.y, width, height);
                    this.drawStage(ctx,section);
                }

                
            });
        },
        drawSectionLines(ctx, section) {
            // const boxWidth =  this.mapData.plan.map_seat_wh;
            // const boxHeight = this.mapData.plan.map_seat_wh;
            // const secWidth =  this.mapData.plan.map_seat_wh * section.section_seats;
            // const secHeight = this.mapData.plan.map_seat_wh * section.section_rows;
            const boxWidth =  this.mapData.plan.map_grid_wh;
            const boxHeight = this.mapData.plan.map_grid_wh;
            const secWidth =  this.mapData.plan.map_grid_wh * section.section_seats;
            const secHeight = this.mapData.plan.map_grid_wh * section.section_rows;
            for (let row = 1; row < section.section_rows; row++) {
                const y = section.y + row * boxHeight;
                ctx.beginPath();
                ctx.moveTo(section.x, y);
                ctx.lineTo(section.x + secWidth, y);
                ctx.stroke();
            }
            
            for (let col = 1; col < section.section_seats; col++) {
                const x = section.x + col * boxWidth;
                ctx.beginPath();
                ctx.moveTo(x, section.y);
                ctx.lineTo(x, section.y + secHeight);
                ctx.stroke();
            }
        },
        // Calculate seat numbers and draw them
        drawSeatNumbering(ctx, section) {
            /*
            let sInd = section.section_index?section.section_index:'A';
            ctx.fillStyle = section.section_text_color;
            // Convert ASCII code to character
            let sIndAscii = sInd.charCodeAt(0) - 1;
            let rowGen = 1;
            let letterCount = 0;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = '12px Arial';
            for (let row = 1; row <= section.section_rows; row++) {
                for (let col = 1; col <= section.section_seats; col++) {
                    let sx = section.x + (col - 1) * this.mapData.plan.map_seat_wh;
                    let sy = section.y + (row - 1) * this.mapData.plan.map_seat_wh;
                    // Combine row letter and column number to create seat label
                    let seatLabel = String.fromCharCode(sIndAscii + rowGen); // 65 corresponds to 'A'
                    if (letterCount > 0) {
                        for (let i = 0; i < letterCount; i++){
                            seatLabel += seatLabel; 
                        }
                    }
                    // Draw seat
                    // ctx.fillText(seatLabel + col, sx + 2, sy + this.mapData.plan.map_seat_wh / 2);
                    ctx.fillText(seatLabel + col, sx + 15, sy + parseInt(this.mapData.plan.map_seat_wh / 2) );
                }
                rowGen++;
                if ((sIndAscii + rowGen) > 90) {
                    sIndAscii = 65;
                    rowGen = 0;
                    letterCount++;
                }
            } */

            /** map setting from sections generated */
            ctx.fillStyle = section.section_text_color;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.font = '12px Arial';
            for (let row = 1; row <= section.section_rows; row++) {
                for (let col = 1; col <= section.section_seats; col++) {
                    let sx = section.x + (col - 1) * this.mapData.plan.map_seat_wh;
                    let sy = section.y + (row - 1) * this.mapData.plan.map_seat_wh;

                    // Calculate the index of the current seat in the section.seats array
                    let seatIndex = (row - 1) * section.section_seats + (col - 1);
                        
                    // Check if the calculated seatIndex is valid
                    if (seatIndex >= 0 && seatIndex < section.seats.length) {
                        let seat = section.seats[seatIndex];
                        let seatLabel = seat.seat_name ? seat.seat_name : "";
                        ctx.fillText(seatLabel, sx + 15, sy + parseInt(this.mapData.plan.map_seat_wh / 2));
                    }
                }
            }
        },
        
        drawStage(ctx,section) {
            ctx.fillStyle = section.section_text_color;
            ctx.font = '12px Arial'; // Set font size
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            // console.log(section.section_name)
            let cx = parseInt(section.x + parseInt(section.section_seats * this.mapData.plan.map_grid_wh) / 2 );
            let cy = parseInt(section.y + parseInt( section.section_rows * this.mapData.plan.map_grid_wh) / 2 );  
            // console.log(cx, cy);

            const maxTextWidth = section.section_seats * this.mapData.plan.map_grid_wh; // Maximum width for the text

            // Measure the width of the text
            const measuredTextWidth = ctx.measureText(section.section_name).width;

            // If the measured text width exceeds the maximum width, truncate and add ellipsis
            let displayText = section.section_name;
            if (measuredTextWidth > maxTextWidth) {
                const ellipsisWidth = ctx.measureText('...').width;
                let availableWidth = maxTextWidth - ellipsisWidth;
                let truncatedText = '';
            
                for (let char of section.section_name) {
                    const charWidth = ctx.measureText(char).width;
                    if (availableWidth >= charWidth) {
                    truncatedText += char;
                    availableWidth -= charWidth;
                    } else {
                    truncatedText += '...';
                    break;
                    }
                }
                
                displayText = truncatedText;
            }
            // Draw the text at the center of the section
            ctx.fillText(displayText, cx, cy);
        },
        drawBgImage(ctx) {
              // Create an Image object
            const backgroundImage = new Image();
            backgroundImage.src = require('@/assets/images/mapbg1.jpg');
            backgroundImage.addEventListener('load', () => {
                if (this.ctx) {
                    this.ctx.clearRect(0, 0, this.mapData.plan.map_width, this.mapData.plan.map_height); // Clear the canvas
                    console.log("this.ctx");
                    console.log(ctx);
                    this.ctx.drawImage(backgroundImage, 0, 0, this.mapData.plan.map_width, this.mapData.plan.map_height);
                } else {
                    console.error('Canvas context is not valid.');
                }
            })
           
        },
        // Apply the scale to the canvas context
       

    }
}
</script>
<style scoped>
.tooltip {
  z-index: 1;
}
</style>