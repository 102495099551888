<template>
    <div>
        <!-- Map Area Background color Seat Color Dialog -->
        <v-dialog v-model="sectionDialog" width="580" @input="closeEdit" class="plan-setting-dialog" persistent>
            <v-form ref="form" v-model="valid" class="plan-setting-form">
                <v-card-actions class="plan-top-action">
                    <v-row>
                        <v-col md="9">
                            <div class="plan-cf-action-heading">{{ editMapSectionIndex  >= 0 && editMapSectionIndex != null?'Edit':'Add'}} Section</div>
                        </v-col>
                            <v-col md="3" style="text-align:right;">
                                <v-btn
                                color="darken-1"
                                class="ma-2 text"
                                text
                                @click="closeEdit"
                                >X</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card>
                <v-card-text class="pos-cf-body">
                    <v-container>
                        <v-row>
                             <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                                <v-text-field
                                    hide-details="auto"
                                    label="Section Name"
                                    outlined
                                    background-color="#fff"
                                    placeholder="section name"
                                    v-model="section.section_name"
                                    :rules="[(v) => !!v || 'Section name is required']"
                                    required
                                ></v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" class="pt-0 pb-4">
                                <label>Background Color</label>
                                <v-card  
                                    class="rounded-lg"
                                    @click="dialogBg = true"
                                    elevation="0"
                                    :color="section.section_bg_color"
                                    style="width: 100%; height: 30px; border:1px solid black;">
                                </v-card>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" class="pt-0 pb-4">
                                <label>Text Caption Color</label>
                                <v-card  
                                    class="rounded-lg"
                                    @click="dialogSc = true"
                                    elevation="0"
                                    :color="section.section_text_color"
                                    style="width: 100%; height: 30px; border:1px solid black;">
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col cols="6" sm="6" md="6" class="pt-0 pb-0">
                                <v-select
                                    v-model="section.section_type"
                                    :items="['Assigned Seating','Stage']"
                                    outlined
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    background-color="#fff"
                                    :label="`Section Type*`"
                                    :rules="[(v) => !!v || 'Section type is required']"
                                     required
                                ></v-select>
                            </v-col>
                            <v-col cols="6" sm="6" md="6" class="pt-0 pb-0">
                                <v-select
                                v-model="section.seat_alignment"
                                :items="['center','left','right']"
                                outlined
                                :menu-props="{ bottom: true, offsetY: true }"
                                background-color="#fff"
                                :label="`Seat Alignment*`"
                                required
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="mt-5">
                            <v-col cols="4" sm="4" md="4" class="pt-0 pb-0">
                                <v-select
                                    v-model="section.section_index"
                                    :items="sectionIndexAlpha"
                                    outlined
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    background-color="#fff"
                                    :label="`Section Index*`"
                                    :readonly="section.is_attached_event?true:false"
                                ></v-select>
                            </v-col>
                            <v-col cols="4" sm="4" md="4" class="pt-0 pb-0">
                                <v-text-field
                                    hide-details="auto"
                                    label="Rows *"
                                    :min="1"
                                    :max="50"
                                    outlined
                                    background-color="#fff"
                                    v-model="section.section_rows"
                                    :rules="[
                                        v => !!v || 'This field is required',
                                        v => (v && v >= 1) || 'Minimum 1 row required',
                                        v => (v && v <= 50) || 'Max should not exceed 50'
                                    ]"
                                    required
                                    type="number"
                                    :readonly="section.is_attached_event?true:false"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="4" sm="4" md="4" class="pt-0 pb-0">
                                <v-text-field
                                    hide-details="auto"
                                    label="Seats Per Row *"
                                    :min="1"
                                    :max="50"
                                    outlined
                                    background-color="#fff"
                                    v-model="section.section_seats"
                                    :rules="[
                                        v => !!v || 'This field is required',
                                        v => (v && v >= 1) || 'Minimum 1 row required',
                                        v => (v && v <= 50) || 'Max should not exceed 50'
                                    ]"
                                    required
                                    type="number"
                                    :readonly="section.is_attached_event?true:false"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions class="pos-cf-bottom-action">
                    <v-spacer></v-spacer>
                    <v-btn
                        color=" darken-1"
                        class="ma-2 white--text blue-color"
                        @click="save"
                        v-if="checkWritePermission($modules.space.management.slug)"
                        text
                    >Save Changes</v-btn>
                </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <!-- End Map Area Background color Seat Color Dialog -->
        <v-dialog v-model="dialogBg" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5"> Select Background Color</v-card-title>
                <v-card-text>
                    <v-color-picker
                        v-model="section.section_bg_color"
                        :swatches="colorOptions"
                        show-swatches
                        dot-size="25"
                        hide-canvas
                        hide-inputs
                        hide-mode-switch
                        hide-sliders
                        mode="hexa"
                    ></v-color-picker>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- <v-btn color="red" text @click="dialogDark = false">
                        Cancel
                    </v-btn> -->
                    <v-btn
                        color="green darken-1"
                        outlined
                        @click="dialogBg = false"
                    >
                        Done
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Set Seat Color Dialog -->
        <v-dialog v-model="dialogSc" persistent max-width="290">
            <v-card>
                <v-card-title class="text-h5"> Select Seat Color </v-card-title>
                <v-card-text>
                <v-color-picker
                    v-model="section.section_text_color"
                    :swatches="colorOptions"
                    show-swatches
                    dot-size="25"
                    hide-canvas
                    hide-inputs
                    hide-mode-switch
                    hide-sliders
                    mode="hexa"
                ></v-color-picker>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    outlined
                    @click="dialogSc= false"
                >
                    Done
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- End Set Seat Color Dialog -->
    </div>
</template>

<script>
export default {
    props: {
        sectionDialog: { type: Boolean, default: false },
        section: { type: Object, default: function () { return {} } },
        editMapSectionIndex: { type: Number, default: null },
        seatMapId: { type: Number, default: null },
    },
    computed: {
        colorSwatches() {
            return () => this.colorOptions;
        },
    },
    data() {
        return {
            sectionIndexAlpha: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
            dialogBg: false,
            dialogSc: false,
            valid: true,
            colorOptions: [
                ["#8FFBD7", "#419AF7", "#3F33CC"],
                ["#326056", "#E67F47", "#FEFA70"],
                ["#BCBDC1","#B10823","#0000"]
            ],
            mapAreaList: [
                {
                    id: '650:350',
                    name: '650 x 350'
                },
                {
                    id: '800:600',
                    name: '800 x 600'
                },
                {
                    id: '900:700',
                    name: '900 x 700'
                },
                {
                    id: '1000:800',
                    name: '1000 x 800'
                },
            ],
            seatSizeList: [
                {
                    id:'10:10',
                    name: '10 x 10'
                },
                {
                    id: '15:15',
                    name: '15 x 15'
                },
                {
                    id: '20:20',
                    name: '20 x 20'
                },
                {
                    id: '30:30',
                    name: '30 x 30'
                },
            ],
        };
    },
    methods: {
        closeEdit() {
            if (!this.$refs.form.validate() && this.editMapSectionIndex > 0) {
                this.showError("Fill all required fields");
                return;
            }
            this.$refs.form.resetValidation();
            this.$emit('close');
        },
        save() {
            if (!this.seatMapId) {
                this.showError("Seat map ID is not set, please go back and try again");
                return;
            }
            if (!this.$refs.form.validate()) {
                this.showError("Fill all required fields");
                return;
            }
            if (this.section.section_seats <= 0 || this.section.section_seats > 50 ) {
                 this.showError("section seats should be minimum: 1 and maximum 50");
                return;
            }
            if (this.section.section_rows <= 0 || this.section.section_rows > 50 ) {
                 this.showError("section rows should be minimum: 1 and maximum 50");
                return;
            }
            
            this.showLoader();
            let formData = new FormData();
            Object.keys(this.section).forEach((key) => {
                let value = this.section[key];
                if (value != null) {
                    formData.append(key, value);
                }
            });
            formData.append('seat_map_id', this.seatMapId);
            let url = `venues/seat-maps/sections`;
            if (this.section.id && this.editMapSectionIndex != null) {
                url += `/${this.section.id}`;

            }
            this.showLoader();
            this.$http.post(url, formData).then((response) => {
                if (response.status == 200 && response.data.status == true) {
                    this.showSuccess("Section Updated Successfully.");
                    console.log(response.data.data);
                    this.section.id = response.data.data.id;
                    this.section.seats = response.data.data.seats;
                    this.$refs.form.resetValidation();
                    this.$emit('save');
                    this.hideLoader();
                } else {
                    this.hideLoader();
                    this.showError("Something went wrong");
                    return;
                }
            }).catch((error) => {
                this.hideLoader();
                this.errorChecker(error);
            });
        },
        
    },
};
</script>
<style scoped>

form.v-form.plan-setting-form {
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
}
.plan-cf-action-heading {
    color: var(--dark-blue, #112A46);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 18px;
}
</style>