<template>
<v-container fluid>
    <v-row v-if="mapData.isPlanSet" no-gutters>
        <v-col lg="12" md="12" sm="12">
          <v-btn small @click="goBack" style="float:right;" class="mr-2">
            <v-icon small>mdi-backburger</v-icon>Back
          </v-btn>
        </v-col>
        <v-col lg="8" md="8" sm="8">
            <v-card class="seat-map-editor-div">
               <v-card-actions>
                  <div class="heading"><h3>Seat Mapping & Allocation</h3></div>
                </v-card-actions>
                <v-divider></v-divider>
                <SeatMapEditor  
                :renderCanvas="renderCanvas" 
                :renderSection="renderSection" 
                :mapData="mapData" 
                :mapSections="mapSections" 
                :seatMapStyles="seatMapStyles"
                :scale="scale"
                />
            </v-card>
        </v-col>
        <v-col lg="4" md="4" sm="4">
          <v-card class="ma-2">
              <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title class="pos-title-text" v-if="checkWritePermission($modules.space.management.slug)">
                        <v-btn small class="white--text blue-color mt-2 mb-2" @click="addMapSection">Draw Section</v-btn>
                        <v-btn small class=" white--text blue-color ma-2" style="float: right" @click="saveMapSectionDesign">Save Design</v-btn>
                        
                      </v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <v-list v-if="mapSections.length" class="cart-sidebar-item">
                  <v-list-item-group v-for="(section,index) in mapSections" :key="index" >
                  <v-list-item class="cart-product-list-item">
                      <v-list-item-content class="cart-product-description">
                          <v-list-item-title class="cart-product-title">
                              <v-row>
                              <v-col cols="2">
                                  <v-card  
                                          class="rounded-lg"
                                          elevation="0"
                                          :color="section.section_bg_color"
                                          style="width: 100%; height: 20px; border:1px solid black;">
                                      </v-card>
                                  </v-col>
                                  <v-col cols="6">
                                      <h4>{{ section.section_name }}</h4>
                                      <h6>Total Seats: {{  section.seats?section.seats.length:0 }}</h6>
                                  </v-col>
                                  <v-col cols="4">  
                                      <v-icon class='dark-blue-color ma-1' @click="editSectionModel(section,index,'section')" title="Edit Section" v-if="checkWritePermission($modules.space.management.slug)">mdi-pencil-outline</v-icon>
                                       <v-icon class='dark-blue-color ma-1' @click="editSectionModel(section,index,'seat')" title="Disable Section Seats" v-if="checkWritePermission($modules.space.management.slug)">mdi-eye-outline</v-icon>
                                          <!-- <v-icon class='dark-blue-color ma-1' @click="rotateMapSection(section,index)" title="Edit Section" v-if="checkWritePermission($modules.pos.management.slug)">mdi-rotate-right</v-icon> -->
                                      <v-icon class='dark-blue-color ma-1' @click="deleteSectionModel(section,index)" title="Delete Section" v-if="checkDeletePermission($modules.space.management.slug)">mdi-delete-outline</v-icon>
                                  </v-col>
                                  </v-row>
                          </v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
                  </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
      </v-row>
   
    <MapSectionForm :section="section" :seatMapId="mapData.plan.id" :editMapSectionIndex="editMapSectionIndex" :sectionDialog="sectionDialog" @close="closeMapSectionForm" @save="saveSection"/>
    <v-dialog v-model="seatDialog" width="580" @input="closeEdit" class="plan-setting-dialog" persistent>
      <MapSectionSeats 
        :mapSection="section" 
        :seatMapId="mapData.plan.id"
        :seatAD="true"  
        @close="closeEdit" 
        @save="saveSection" 
        :key="renderSeatSection" 
        @closeUpdate="closeUpdateMapSectionSeat" />
    </v-dialog>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
 </v-container>

</template>
<script>
import MapSectionForm from './MapSectionForm.vue';
import MapSectionSeats from '@/components/SeatMap/MapSectionSeats.vue';
import SeatMapEditor from './SeatMapEditor.vue';

import ConfirmModel from "@/components/Confirmation/ConfirmModel";
import constants from "../../utils/constants";

export default {
  components: { MapSectionForm,MapSectionSeats,SeatMapEditor,ConfirmModel },
  data() {
    return {
      sectionDialog: false,
      seatDialog: false,
      editImageHover: false,
      time: constants.TIMINGS,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      editMapSectionIndex: null,
      section: {
        id:null,
        section_index: '',
        section_name: '',
        section_type: '',
        seat_alignment: "center",
        x: 0,
        y: 0,
        section_rows: 3,
        section_seats: 5,
        default_seat_price: 10.00,
        section_bg_color: "#D3D3D3FF",
        section_text_color: "#fff",
        is_transparent_bg: false,
        is_border: true,
        rotation: 0,
        status_id: 1,
        seats:[],
      
      },
      mapData: {
        isPlanSet: false,
        plan: {
          id: null,
          map_bg_color: "#D3D3D3FF",
          map_grid_line_color: "#fff",
          map_area: "800:600",
          map_width: 800,
          map_height:600,
          map_seat_area: "20:20",
          map_seat_wh: 20,
          map_image: "",
        }
      },
      mapSections: [],
      newSectionName: '',
      renderCanvas: false,
      renderSection: false,
      refreshMap: 0,
      rotateSection: null,
      scale: 1,
      scaleStep: 0.1,
      renderSeatSection:0,
    };
  },
  computed: {
    seatMapStyles() {
      return {
        backgroundColor: this.mapData.plan.map_bg_color,
        width: this.mapData.plan.map_width+"px",
        height: this.mapData.plan.map_height+"px",
        margin: "0 auto",
        border: "1px solid "+this.mapData.plan.map_bg_color,
        borderRadius:"1px",
      };
    },
  },
  mounted() {
    this.getRouteParams();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getRouteParams() {
      if (this.$route.params.data) {
        let mapPlanId = JSON.parse(atob(this.$route.params.data));
        this.getMapPlan(mapPlanId);
      }else{
        this.$router.push({
          name: "Spaces",
        });
      }
    },
   
    confirmActions(data) {
      if (data.type == "delete_section") {
        this.deleteSection(data.id);
      } 
      this.confirmModel.id = null;
    },

    getMapPlan(id) {
      this.showLoader("Loading");
      this.$http.get(`venues/seat-maps/${id}`)
      .then((response) => {
          if (response.status == 200) {
            if (response.data && response.data.data) {
                this.mapData.isPlanSet = true;
                this.mapData.plan = response.data.data;
                this.getSeatMapSections(id)
              }else{
                  this.showError('Data not found');
              }
              this.hideLoader();
          }
      })
      .catch((error) => {
          console.log(error);
          this.hideLoader();
      });  
    },
    
    closeEdit(sectionReload = false) {
      this.seatDialog = false;
      this.renderCanvas = false;
      this.sectionDialog = false;
      this.resetDefaultSection();
      if (sectionReload) {
        this.getSeatMapSections(this.mapData.plan.id);
      }
      
    },
    closeUpdateMapSectionSeat() {
      this.seatDialog = false;
      this.resetDefaultSection();
      this.getSeatMapSections(this.mapData.plan.id);

    },
    getSeatMapSections(seat_map_id) {
      this.showLoader("Loading");
      this.$http.get(`venues/seat-maps/sections?seat_map_id=${seat_map_id}`)
      .then((response) => {
          if (response.status == 200) {
            if (response.data && response.data.data) {
              this.mapSections = response.data.data;
              this.renderSection = false;
              this.$nextTick(() => { 
                this.renderSection = true;
              })
            }
            this.hideLoader();
          }
      })
      .catch((error) => {
          console.log(error);
          this.hideLoader();
      });  
    },
    addMapSection() {
      if (this.mapData.plan.id) {
        this.sectionDialog = true;
      } else {
          this.showError("Seat map ID is not set, please go back and try again");
          return;
      }
    },
    saveMapSectionDesign() {
      let formData = new FormData();
      formData.append('seat_map_id', this.mapData.plan.id);
      this.mapSections.forEach((section,index) => {
          formData.append(`section[${index}][id]`, section.id);
          formData.append(`section[${index}][x]`, section.x);
          formData.append(`section[${index}][y]`, section.y);
      });
      let url = `venues/seat-maps/update`;
      this.showLoader();
      this.$http.post(url, formData).then((response) => {
          if (response.status == 200 && response.data.status == true) {
              this.showSuccess("Design saved successfully.");
              this.hideLoader();
          } else {
              this.hideLoader();
              this.showError("Something went wrong");
              return;
          }
      }).catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
      });
    },
    saveSection() {
      this.sectionDialog = false;
      const mapSection = Object.assign({}, this.section);
      if (this.editMapSectionIndex >= 0 && this.editMapSectionIndex != null) {
        const coordinate = this.getCoordinate(mapSection);
        mapSection.x = coordinate.x;
        mapSection.y = coordinate.y;
        mapSection.id = this.section.id;
        this.mapSections[this.editMapSectionIndex] = mapSection;
        // this.$set(this.mapSections[this.editMapSectionIndex], mapSection);
        this.editMapSectionIndex = null;
        this.renderSection = false;
        this.$forceUpdate();
        this.$nextTick(() => {
          this.renderSection = true;
        });
      }else{
        if (mapSection.section_type == 'Stage') {
          if (this.isStageAlreadyExist()) {
            this.showError("Stage Already Exist, You can add only one stage");
            return;
          }
        }
        const coordinate = this.getCoordinate(mapSection);
        mapSection.x = coordinate.x;
        mapSection.y = coordinate.y;
        mapSection.id = this.section.id;
        this.mapSections.push(mapSection);
        this.renderSection = false;
        this.$nextTick(() => { 
          this.renderSection = true;
        })
      }
      this.resetDefaultSection();

    },
    resetDefaultSection() {
      this.section = {
        section_index: '',
        section_name: '',
        section_type: '',
        seat_alignment: "center",
        x: 0,
        y: 0,
        section_rows: 3,
        section_seats: 5,
        default_seat_price: 10.00,
        section_bg_color: "#D3D3D3FF",
        section_text_color: "#fff",
        is_transparent_bg: false,
        is_border: true,
        rotation: 0,
        status_id: 1,
        seats: [],
      };
    },
    getCoordinate(mapSection) {
      if (mapSection.seat_alignment == "left") {
        //this.mapData.plan.map_seat_wh
        let x = this.mapData.plan.map_grid_wh;
        let y = this.mapData.plan.map_grid_wh;
        return { x: x, y: y};
      } else if (mapSection.seat_alignment == "center") {
        let x = ( (this.mapData.plan.map_width / 2) - (parseInt(mapSection.section_seats / 2)) * this.mapData.plan.map_grid_wh);
        let y = this.mapData.plan.map_grid_wh;
        return { x: x, y: y};
      } else if (mapSection.seat_alignment == "right") {
          let x = (this.mapData.plan.map_width - (this.mapData.plan.map_grid_wh * mapSection.section_seats + this.mapData.plan.map_grid_wh));
          let y =  this.mapData.plan.map_grid_wh;
          return { x: x, y: y};
      } else {
        let x = this.mapData.plan.map_grid_wh;
        let y = this.mapData.plan.map_grid_wh;
        return { x: x, y: y};
      }
    },
    isStageAlreadyExist() {
      let isExist = false;
      this.mapSections.forEach(section => { 
        if (section.section_type == 'Stage') {
          isExist = true;    
        }
      });
      return isExist;
    },
    editSectionModel(section, index, type) {
      if (section.id && this.mapData.plan.id) {
        if (type == 'section') {
          this.editMapSectionIndex = index;
          this.section = Object.assign({}, section);
          this.sectionDialog = true;
          this.seatDialog = false;
        } else if (type == 'seat') {
          this.section = Object.assign({},section);
          this.sectionDialog = false;
          this.seatDialog = true;
          this.renderSeatSection++;
        }
      } else {
        this.showError("Seat map ID or Section Id is not set, please go back and try again");
        return;
      }
    },
    // rotateMapSection(section,index) {
    //   this.renderSection = false;
    //   if (section && index >= 0 && index != null) {
    //     const rotationAngle = section.rotation + ((15 * Math.PI) / 180); 
    //     this.$set(this.mapSections[index], "rotation", rotationAngle);
    //     this.$nextTick(() => { 
    //       this.renderSection = true;
    //     })
    //   }
    // },
    deleteSectionModel(section,index) {
      console.log(section,index);
      this.confirmModel = {
          id: index,
          title: "Do you want to delete this section?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_section",
      }
    },
    deleteSection(index) {
      this.renderSection = false;
      const section = this.mapSections[index];
      if (section) {
        this.showLoader();
        this.$http.delete(`venues/seat-maps/sections?seat_map_id=${this.mapData.plan.id}&section_id=${section.id}`).then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Section deleted successfully");
            this.mapSections.splice(index, 1);
            this.hideLoader();
            this.$nextTick(() => { 
              this.renderSection = true;
            })
          }
        }).catch((error) => {
           this.hideLoader();
            this.errorChecker(error);
        });
      }
    },
    closeMapSectionForm() {
      this.sectionDialog = false;
      this.editMapSectionIndex = null;
      this.section = {
        id: null,
        section_index: '',
        section_name: '',
        section_type: '',
        seat_alignment: "center",
        x: 0,
        y: 0,
        section_rows: 3,
        section_seats: 5,
        default_seat_price: 10.00,
        section_bg_color: "#D3D3D3FF",
        section_text_color: "#fff",
        is_transparent_bg: false,
        is_border: true,
        rotation:0,
      };
    }
  },
};
</script>
<style>
.seat-map-editor {
  position: relative;
}
.seat-map-editor-div .heading{
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}
</style>