<template>
    <div class="seating-arrangement" style="overflow-x:auto">
        <v-card class="seating-card">
            <v-card-actions v-if="!eventTicketAssignForm">
                <v-row>
                    <v-col md="9">
                        <h3>Section Name: {{ mapSection && mapSection.section_name?mapSection.section_name:'' }}</h3>
                    </v-col>
                    <v-col md="3" style="text-align:right;">
                        <v-btn
                        v-if="!eb"
                        color="darken-1"
                        class="text"
                        text
                        @click="closeEdit"
                        >X</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-text class="">
                <v-list-item v-if="!eb && selectedSeats && selectedSeats.length && seatAD" >
                    <v-list-item-content>
                        <v-list-item-title class="pos-title-text">
                            <h6  style="display:initial;font-size:14px;">
                                Selected Seats:- 
                                <v-chip
                                    v-for="(ss, ssIndex) in selectedSeats"
                                    :key="ssIndex"
                                    class="ma-2"
                                    color="green"
                                    outlined
                                >
                                    <v-icon left class="seat-icon">
                                       mdi-seat
                                    </v-icon>
                                    {{ ss.seat_name }}
                                </v-chip>
                                <!-- <span class="selected-seat" v-for="(ss, ssIndex) in selectedSeats" :key="ssIndex">{{ ss.seat_name }}</span> -->
                            </h6>
                            <h6  class="text-right" v-if="seatAD">
                                <v-btn small class="ma-1 blue-color white--text" @click="updateSeatsModel('activate')">Activate Seats</v-btn>
                                <v-btn small class="ma-1 warning white--text" @click="updateSeatsModel('deactivate')">Deactivate Seats</v-btn>
                            </h6>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <div :class="`seats-container ${eventTicketAssignForm?'etaf':''} ${seatAD?'seatAD':''} ${eb?'eb':''}`" v-if="section && section.seats && section.seats.length > 0" style="">
                    <div class="seat-row" v-for="(row, rowIndex) in section.seats" :key="rowIndex">
                        <v-checkbox
                            v-if="eventTicketAssignForm"
                            style="margin-top: 1px"
                            v-model="row.isSettle"
                            color="#66c8c8"
                            :value="row.isSettle"
                            @change="changeCB(row, rowIndex)"
                            hide-details
                        ></v-checkbox>
                        <div 
                            :class="`seat ${seat.is_selected?'selected':''} ${seat.is_booked?'booked':''} ${seat.status?seat.status:''}  ${seat.event_ticket_id?'event_ticket':''} ${seat.status_id == 2?'disabled':''}`"
                            v-for="(seat, colIndex) in row" :key="colIndex" 
                            @click="toggleSeat(rowIndex,colIndex,seat)"
                            >
                            <div :class="`seat-inner`" :title="`${getTicketName(seat.event_ticket_id)}`">
                                {{  seat.seat_name }}
                                <v-icon center class="seat-icon">
                                    mdi-seat
                                </v-icon>
                            </div>
                        </div>
                    </div>
                </div><div class="seat-container no-ticket" v-else-if="eventTicketAssignForm || seatAD"><h3 class="text-center heading pa-10">Ticket Not Assigned</h3></div>
            </v-card-text>
        </v-card>
        <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
        ></confirm-model>
    </div>
   
</template>
<script>
import ConfirmModel from "@/components/Confirmation/ConfirmModel";
export default {
    components: {ConfirmModel},
    props: {
        seatMapId:{type: Number, default:null},
        mapSection: { type: Object, default: null },
        eventTicketAssignForm: { type: Boolean, default: false },
        tickets: { type: Array, default: () => [], },
        renderSeats: { type: Boolean, default: false },
        seatAD: { type: Boolean, default: false },
        eb:{ type:Boolean, default: false},
        isShowToolTip: { type: Boolean, default: false },
        
    },
    watch: {
        renderSeats: {
            immediate: true,
            handler(val) {
                console.log(val);
                console.log("seat map");
                console.log("");
                if (val && this.mapSection && this.mapSection.seats) {
                    this.selectedSection(this.mapSection);
                } else if(val && this.mapSection && !this.mapSection.seats) {
                    this.section = null;
                    // console.log("else watch")
                }
            },
        }
    },
    data() {
        return {
            sectionDialog: false,
            editImageHover: false,
            confirmModel: {
                id: null,
                title: null,
                description: null,
            },
            mapData: {},
            mapSections: [],
            newSectionName: '',
            refreshMap: 0,
            rotateSection: null,
            selectedSeats: [],
            section: null,
            selectedRow: [],
        };
    },
    computed: {},
    mounted() {
        if (this.mapSection && this.mapSection.seats) {
            this.selectedSection(this.mapSection);
        }
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        confirmActions(data) {
            this.updateSeats(data.type);
            this.confirmModel.id = null;
        },
        closeEdit() {
            this.section = null;
            if (this.selectedSeats.length) {
                this.$emit('close',true);
            } else {
                 this.$emit('close');
            }
            
        },
        saveMapSectionDesign() { },
        selectedSection(mapSection) {
            // if (! this.eb) {
            //     this.selectedSeats = []; 
            // }
            this.selectedSeats = []; 
            this.section = Object.assign({}, mapSection);
            if (mapSection && mapSection.seats) {
                let rows = [];
                for (let row = 0; row < mapSection.section_rows; row++){
                    rows[row] = [];
                    for (let col = 0; col < mapSection.section_seats; col++){
                        let seatIndex = 0;
                        seatIndex = ((row + 1) - 1) * mapSection.section_seats + ((col + 1) - 1);
                        if (seatIndex >= 0 && seatIndex < mapSection.seats.length) {

                            let seat = mapSection.seats[seatIndex];

                            rows[row][col] = seat;
                        }
                    }
                }
                this.section.seats = rows;
            }
            
            
        },
        toggleSeat(row, col, seat) {
            if (seat.is_booked) {
                this.showError("Seat is already booked");
                return false;
            } else if (this.eb && seat.status_id == 2) {
                this.showError("Seat is disabled");
                return false;
            }
            else if (this.eb && !seat.event_ticket_id) {
                this.showError("Ticket not assigned to this seat, please choose an other");
                return false;
            }
            if (!this.eb) {
                if (!this.seatAlreadySelected(seat)) {
                    this.$set(this.section.seats[row][col], 'is_selected', true);
                    if (this.eb) {
                        this.$emit("selectSeat",seat,false);
                    } else {
                        this.selectedSeats.push(seat);
                    }
                    this.hideLoader();
                } else {
                    this.$set(this.section.seats[row][col], 'is_selected', false);
                    const index  = this.selectedSeats.findIndex(s => s.seat_name === seat.seat_name && s.seat_map_section_id == seat.seat_map_section_id);
                    if (index !== -1) {
                        this.selectedSeats.splice(index, 1);
                        if (this.eb) {
                            this.$emit("selectSeat",seat,false);
                        }
                    }
                }
            } else if (this.eb) {
                if (this.section.seats[row][col].is_selected == true) {
                    this.$set(this.section.seats[row][col], 'is_selected', false);
                    this.$emit("selectSeat",seat,false);
                } else{
                    this.$set(this.section.seats[row][col], 'is_selected', true);
                    this.$emit("selectSeat",seat,true);
                }
            }
           
        },
        updateSeatsModel(type) {
            // console.log('seleceted seats');
            // console.log(this.selectedSeats);
            if (type == 'activate') {
                this.confirmModel = {
                    id: this.mapSection.id,
                    title: "Do you want to activate seats?",
                    description:
                    "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
                    type: "activate",

                }
            } else if (type == 'deactivate') {
                this.confirmModel = {
                    id: this.mapSection.id,
                    title: "Do you want to deactivate seats?",
                    description:
                    "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
                    type: "deactivate",

                }
            }
        },
        updateSeats(type) {
            
            this.showLoader();
            let formData = new FormData();
            console.log("this.mapSection");
            console.log(this.mapSection.seat_map_id);
            if (this.mapSection.seat_map_id) {
                formData.append('seat_map_id', this.mapSection.seat_map_id);
            } else {
                formData.append('seat_map_id', this.seatMapId);
            }
            if (this.mapSection.seat_map_section_id && this.mapSection.event_id) {
                formData.append('event_id', this.mapSection.event_id);
                formData.append('seat_map_section_id', this.mapSection.seat_map_section_id); 
            }else{
                formData.append('seat_map_section_id', this.mapSection.id);
            }

            
            // formData.append('event_id',)
            let url = "";
            if (this.eventTicketAssignForm) {
                url = `venues/events/event-map/update-seats`;  
            } else {
                url = `venues/seat-maps/sections/update-seat-status`;  
            }   
            
            let isSeats = false;
            if (type == "activate") {
                formData.append('status_id', 1);
                this.selectedSeats.forEach((seat,index) => {
                    if (seat.status_id == 2) {
                        isSeats = true;
                        formData.append(`seat_name[${index}]`, seat.seat_name);
                    }
                });
            } else if (type == "deactivate") {
                formData.append('status_id', 2);
                this.selectedSeats.forEach((seat,index) => {
                    if (seat.status_id == 1) {
                        isSeats = true;
                        formData.append(`seat_name[${index}]`, seat.seat_name);
                    }
                });
            }
            if (isSeats) {
                this.$http.post(url, formData).then((response) => {
                    if (response.status == 200 && response.data.status == true) {
                        this.showSuccess("Seats updated successfully.");
                        this.selectedSeats = [];
                        this.section = null;
                        this.hideLoader();
                        this.$emit("closeUpdate")
                    } else {
                        this.hideLoader();
                        this.showError("Something went wrong");
                        return;
                    }
                }).catch((error) => {
                    this.hideLoader();
                    this.errorChecker(error);
                });   
            } else {
                this.showSuccess("Seats updated successfully.");
                this.hideLoader();
                this.$emit("closeUpdate")
            }
                
        },
        
        seatAlreadySelected(seat) {
            
            if (this.selectedSeats && this.selectedSeats.length > 0) {
                const selectedSeat = this.selectedSeats.find(item => {
                    return item.seat_name === seat.seat_name && item.seat_map_section_id == seat.seat_map_section_id; 
                });
                return selectedSeat;
            } else {
                return false;
            }
        },

        removeSelectedSection() {
            this.mapSections.map((section) => {
                if (section.selected) {
                    section.selected = false;
                }
            })
        },

        changeCB(row,index) {
            if (row.isSettle) {
                row.forEach((seat, col) => {
                    if ( (this.section.seats[index][col].status_id == 1 || this.seatAD) && !this.section.seats[index][col].is_booked) {
                        this.$set(this.section.seats[index][col], 'is_selected', true);
                        this.selectedSeats.push(seat);
                    }
                }); 
            } else {
                row.forEach((seat, col) => {
                    this.$set(this.section.seats[index][col], 'is_selected', false);
                    const si  = this.selectedSeats.findIndex(s => s.seat_name === seat.seat_name && s.seat_map_section_id == seat.seat_map_section_id);
                    if (si !== -1) {
                        this.selectedSeats.splice(si, 1);
                    }
                });
            }
           
           
        },
        getTicketName(event_ticket_id) {
            if (event_ticket_id) {
                const matchingTicket = this.tickets.find(seat => seat.event_ticket_id === event_ticket_id);
                if (matchingTicket) {
                    return matchingTicket.name;
                }
            } 
            return "No Ticket Assigned";           
        }

    }
};
</script>
<style scoped>
/*** Don't change css position ***/
.selected-seat{
    width: 40px;
    display: inline-block;
    background: #ccc;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
    font-size: 12px;;
}

.seat-row {
    width: 100%;
    display:inline-flex;
}

.seat{
    display: inline-block;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    min-width: 40px;
}
.seat:hover {
    background: #ccc;
}
.seat.disabled {
    background-color: #ababab !important;
    color: #fff;
}
.seat-icon{
    font-size: 20px;
    display: block;
}
/** Event ticket assign form : etaf */
.etaf .seat.booked .seat-icon,.eb .seat.booked .seat-icon{
    color: #fff;
}
.etaf .seat.event_ticket {
    background: green;
    color: #fff;
}
.etaf .seat.booked,.eb .seat.booked {
    background: #062b48;
    color: #fff;
    cursor: not-allowed;
}
.etaf .seat.selected,.seat.selected {
    background: #00b0af ;
    /* background:rgb(206, 168, 0); */
    color: #fff !important;
}
/********** END etaf end here ********/

/** Event ticket seats activate deactivate : seatAD */
.seatAD .seat.selected.disabled{
    background: #00b0af  !important;
    /* background:rgb(206, 168, 0) !important; */
    color: #fff;
}
/********** END seatAD end here ********/


/** Event Booking seats : eb */
.eb .seat.disabled,.eb .seat.booked,.etaf .seat.booked{
    cursor: not-allowed;
}
.eb .seat.booked.unpaid,.etaf .seat.unpaid{
    background:rgb(206, 168, 0);
    color:#fff;
}
.eb .seat.booked.unpaid .seat-icon,.etaf .seat.unpaid .seat-icon{
    color: #fff;
}
/********** END eb end here ********/

</style>